// 小程序积分设置
<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:">积分管理</a>
          <i>></i>
          <a class="cur-a" href="javascript:" @click="$router.back()">积分设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="addlist">
              <div>
                <div class="form-box">
                  <el-form ref="baseform" :model="baseform" :rules="rules" class="form" label-width="10rem">
                    <h3>
                      <span>基本信息设置</span>
                    </h3>
                    <el-form-item label="积分名称：" prop="name">
                      <div class="df">
                        <el-input v-model="baseform.name" class="input_w"></el-input>
                      </div>
                      <p style="color: #999">小程序前台积分的名称</p>
                    </el-form-item>
                    <el-form-item label="积分兑换：" prop="proportion">
                      <div class="df">
                        <el-input v-model="baseform.proportion" class="input_w"></el-input>
                        <span style="width: 80px; margin-left: 5px">积分=1元</span>
                      </div>
                    </el-form-item>
                    <el-form-item label="积分规则：" prop="proportionRule">
                      <div class="flexdc">
                        <el-input v-model="baseform.proportionRule" :rows="6" class="input_w_80" resize="none"
                                  type="textarea"></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="积分攻略：" prop="raiders">
                      <div class="flexdc">
                        <el-input v-model="baseform.raiders" :rows="6" class="input_w_80" resize="none"
                                  type="textarea"></el-input>
                      </div>
                    </el-form-item>
                    <h3>
                      <span>积分任务设置</span>
                    </h3>
                    <h4>
                      <span>注册登录送积分</span>
                      <p style="color: #999;font-weight: normal">（当用户第一次登录注册小程序时送相应积分）</p>
                    </h4>
                    <el-form-item label="注册登录：" prop = "taskList[0].obtainIntegral">
                      <div class="df">
                        <el-input v-model="baseform.taskList[0].obtainIntegral" class="input_w"></el-input>
                        <span style="width: 80px; margin-left: 5px">积分</span>
                      </div>
                    </el-form-item>
                    <h4>
                      <span>邀请好友送积分</span>
                      <p style="color: #999;font-weight: normal">（用户邀请*个好友，可得到今日积分。）</p>
                    </h4>
                    <div style="display:flex" >
                      <el-form-item label="每日送积分：" prop = "taskList[1].obtainIntegral">
                        <div class="df">
                          <el-input v-model="baseform.taskList[1].obtainIntegral" class="input_w_80"></el-input>
                          <span style="width: 80px; margin-left: 5px"></span>
                        </div>
                      </el-form-item>
                      <el-form-item label="好友数量：" prop = "taskList[1].exchangeClaim">
                        <div class="df">
                          <el-input v-model="baseform.taskList[1].exchangeClaim" class="input_w_80"></el-input>
                          <span style="width: 80px; margin-left: 5px"></span>
                        </div>
                      </el-form-item>
                    </div>
                    <h4>
                      <span>课程学习送积分</span>
                      <p style="color: #999;font-weight: normal">（用户学习*节课，可得到今日积分。）</p>
                    </h4>
                    <div style="display:flex" >
                      <el-form-item label="每日送积分：" prop = "taskList[2].obtainIntegral">
                        <div class="df">
                          <el-input v-model="baseform.taskList[2].obtainIntegral" class="input_w_80"></el-input>
                          <span style="width: 80px; margin-left: 5px"></span>
                        </div>
                      </el-form-item>
                      <el-form-item label="课程章节数量：" prop = "taskList[2].exchangeClaim">
                        <div class="df">
                          <el-input v-model="baseform.taskList[2].exchangeClaim" class="input_w_80"></el-input>
                          <span style="width: 80px; margin-left: 5px"></span>
                        </div>
                      </el-form-item>
                    </div>
                    <h4>
                      <span>题库学习送积分</span>
                      <p style="color: #999;font-weight: normal">（用户练习*道题，可得到今日积分。）</p>
                    </h4>
                    <div style="display:flex" >
                      <el-form-item label="每日送积分：" prop = "taskList[3].obtainIntegral">
                        <div class="df">
                          <el-input v-model="baseform.taskList[3].obtainIntegral" class="input_w_80"></el-input>
                          <span style="width: 80px; margin-left: 5px"></span>
                        </div>
                      </el-form-item>
                      <el-form-item label="习题数量："  prop = "taskList[3].exchangeClaim">
                        <div class="df">
                          <el-input v-model="baseform.taskList[3].exchangeClaim" class="input_w_80"></el-input>
                          <span style="width: 80px; margin-left: 5px"></span>
                        </div>
                      </el-form-item>
                    </div>

                    <h4>
                      <span>购买课程送积分</span>
                      <p style="color: #999;font-weight: normal">（用户成功购买课程后，送该课程价格的一部分积分。）</p>
                    </h4>

                    <el-form-item label="购买课程送积分："  prop = "taskList[4].obtainProportion">
                      <div class="df">
                        <el-input v-model="baseform.taskList[4].obtainProportion" class="input_w"></el-input>
                        <span style="width: 14px; margin-left: 5px">%</span>
                        <span style="color: #999">（课程价格的百分比）</span>
                      </div>
                    </el-form-item>
                    <h4>
                      <span>购买题库送积分</span>
                      <p style="color: #999;font-weight: normal">（用户成功购买题库后，送该课程价格的一部分积分。）</p>
                    </h4>

                    <el-form-item label="购买题库送积分：" prop = "taskList[5].obtainProportion">
                      <div class="df">
                        <el-input v-model="baseform.taskList[5].obtainProportion" class="input_w"></el-input>
                        <span style="width: 14px; margin-left: 5px">%</span>
                        <span style="color: #999">（题库价格的百分比）</span>
                      </div>
                    </el-form-item>


                  </el-form>
                </div>
              </div>
              <div class="btn-box">
                <el-button class="bgc-bv" type="primary" @click="doAddSave">确 定</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let editor = {};
export default {
  name: "PointsSet",
  components: {},
  data() {
    let checkInt = (rule, value, callback) => {
      value = value || "";
      let reg = /^\+?[1-9][0-9]*$/;
      let msg = "此项";
      if (value == "") {
        callback(new Error(msg + "不能为空"));
      } else if (!reg.test(value)) {
        callback(new Error(msg + "应为正整数"));
      }if(value.length>4){
        callback(new Error(msg + "不能超过4位"));
      } else {
        callback();
      }
    };
    return {
      // 基本信息
      baseform: {
        name:'',
        proportion:'',
        proportionRule:'',
        raiders:'',
        taskList:[{}]
      },
      rules: {
        'taskList[0].obtainIntegral': [
          {required: true, trigger: "blur", validator: checkInt},
        ],
        'taskList[1].obtainIntegral': [
          {required: true, trigger: "blur", validator: checkInt},
        ],
        'taskList[2].obtainIntegral': [
          {required: true, trigger: "blur", validator: checkInt},
        ],
        'taskList[3].obtainIntegral': [
          {required: true, trigger: "blur", validator: checkInt},
        ],
        'taskList[1].exchangeClaim': [
          {required: true, trigger: "blur", validator: checkInt},
        ],
        'taskList[2].exchangeClaim': [
          {required: true, trigger: "blur", validator: checkInt},
        ],
        'taskList[3].exchangeClaim': [
          {required: true, trigger: "blur", validator: checkInt},
        ],
        'taskList[4].obtainProportion': [
          {required: true, trigger: "blur", validator: checkInt},
        ],
        'taskList[5].obtainProportion': [
          {required: true, trigger: "blur", validator: checkInt},
        ],

        withdrawMin: [{required: true, trigger: "blur", validator: checkInt}],
        withdrawDailyLimit: [
          {required: true, trigger: "blur", validator: checkInt},
        ],
        name: [
          {required: true, trigger: "blur", message: "请输入积分名称"},
          { min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur' }
        ],
        proportion: [
          {required: true, trigger: "blur", validator: checkInt},
        ],
        proportionRule: [
          {required: true, trigger: "blur", message: "请输入积分兑换规则"},
          { min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur' }
        ],
        raiders: [
          {required: true, trigger: "blur", message: "请输入积分攻略"},
          { min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur' }
        ],
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // this.editorInit();
    // this.editorInit1();
  },
  computed: {},
  methods: {
    init() {
      this.queryData();
    },
    // 新增保存
    doAddSave() {
      this.$refs.baseform.validate((valid) => {
        if (valid) {
          if (this.baseform.updateTime) {
            delete this.baseform.updateTime;
          }
          this.$post(
              "/biz/integral/insertOrUpdate",
              {
                ...this.baseform,
              },
              3000,
              true,
              2
          ).then((res) => {
            if (res.status === "0") {
              this.$message({
                type: "success",
                message: "保存成功",
              });
            }
          });
        }
      });
    },
    // 编辑查询
    queryData() {
      this.$post("/biz/integral/getInfo", {}, 3000, false, 2).then(
          (res) => {
            ;
            if (res.status === "0") {

              this.baseform = res.data;
              // editor.txt.html(res.data.exchangeRemark);
              // editor1.txt.html(res.data.withdrawRemark);
            }
          }
      );
    },
  }
};
</script>
<style lang="less">
.addlist {
  h3 {
    margin-left: 20px;
    display: flex;
    border-left: 4px solid #4574f9;

    span {
      margin-left: 20px;
    }

    margin-bottom: 30px;
  }
  h4 {
    margin-left: 20px;
    display: flex;

    span {
      margin-left: 20px;
    }

    margin-bottom: 30px;
  }
}

.form-box {
  // padding: 10px 20px;
  .form {
    // width: 80%;
    // padding: 20px;
  }

  .form-bg {
    background-color: rgb(247, 248, 253);
  }

  .input_w {
    width: 30%;
  }
  .input_w_80 {
    width: 80%;
  }
}

.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  // width: 80%;
  button {
    padding: 12px 30px;
  }
}
</style>
